import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Timestamp } from "firebase/firestore";
import { Container, Form, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';
import { useFirestore } from '../contexts/FirestoreContext';

function HomePage() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const newPasswordRef = useRef(); // Reference for new password input
  const { login, logout, currentUser } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // State for modal visibility
  const [updatingPassword, setUpdatingPassword] = useState(false); // Loading state for password update
  const [privacyPolicy, setPrivacyPolicy] = useState(false); // Loading state for password update
  const [termsCond, setTermsCond] = useState(false); // Loading state for password update
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [modalTermsTitle, setModalTermsTitle] = useState('');
  const { updateData, getDocument } = useFirestore();

  useEffect(() => {
    if (currentUser) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          setRole(idTokenResult.claims.role);
        }
      });
    }
  }, [currentUser]);

  const checkIfPasswordExpired = (timestamp) => {
    if (!timestamp) {
      // If the timestamp is null or undefined, assume the password is expired
      return false;
    }

    const ninetyDaysInMilliseconds = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds
    const now = new Date(); // Current date and time
    const timestampDate = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
    const isExpired = now - timestampDate > ninetyDaysInMilliseconds;

    return isExpired;
  };

  const handleShowTermsModal = (url) => {
    if (url === '/PrivacyStatement.pdf') {
      setModalTermsTitle('Privacy Statement')
    } else {
      setModalTermsTitle('Terms and Conditions')
    }
    setPdfUrl(url);
    setShowTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setShowTermsModal(false);
    setPdfUrl('');
    setModalTermsTitle('');
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);

      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idTokenResult = await user.getIdTokenResult(true); // Force refresh the token
        const role = idTokenResult.claims.role;
        const userData = await getDocument('users', user.uid);
        const passwordExpired = checkIfPasswordExpired(userData.dtPasswordChanged);
        if (idTokenResult.claims.mustChangePassword || passwordExpired) {
          setShowPasswordModal(true); // Show modal if user must change password
        } else if (role === 'Customer') {
          navigate('/customer');
        } else if (role === 'Admin') {
          navigate('/salesorders');
        } else {
          navigate('/');
        }
      }
    } catch {
      setError('Failed to log in');
    }

    setLoading(false);
  }

  async function handlePasswordUpdate() {
    const newPassword = newPasswordRef.current.value;

    // Validate new password length
    if (newPassword.length <= 7) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    const idTokenResult = await user.getIdTokenResult(true); // Force refresh the token
    const role = idTokenResult.claims.role;
    setUpdatingPassword(true);

    try {
      const updateUser = httpsCallable(functions, 'updateUser');
      await updateUser({
        uid: getAuth().currentUser.uid,
        password: newPassword,
        mustChangePassword: false, // Clear the mustChangePassword flag
        role: role,
      });

      let userData = await getDocument('users', user.uid);
      userData.privacyPolicy = true;
      userData.termsConditions = true;
      userData.dtPasswordChanged = Timestamp.now();
      await updateData('users', user.uid, userData);

      setShowPasswordModal(false);
      if (role === 'Customer') {
        navigate('/customer');
      } else if (role === 'Admin') {
        navigate('/salesorders');
      } else {
        navigate('/');
      }
    } catch (error) {
      setError('Failed to update password: ' + error.message);
    }

    setUpdatingPassword(false);
  }

  async function handleModalClose() {
    setShowPasswordModal(false); // Close the modal first
    await logout(); // Log the user out
    navigate('/'); // Redirect to the login screen
  }

  return (
    <div>
      <Container className="text-center mt-3">
        <h1>Welcome to the Hardy Fleet Portal!</h1>
        <img
          src={require('../assets/full-logo.png')}
          alt="Full Logo"
        />
        <h6>(770) 445-6655</h6>
      </Container>
      {!currentUser && (
        <Container className="d-flex align-items-center justify-content-center mt-3">
          <div className="w-100" style={{ maxWidth: '500px' }}>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Button type="submit" disabled={loading}>Login</Button>
            </Form>
          </div>
        </Container>
      )}

      {/* Modal for password update */}
      <Modal show={showPasswordModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              ref={newPasswordRef}
              required
              placeholder="Must be at least 8 characters"
            />
          </Form.Group>
          <Form.Group controlId="agreeToPolicy" className="mt-3">
            <Form.Check
              type="checkbox"
              label={
                <>
                  I agree to the{' '}
                  <span
                    className="text-primary text-decoration-underline"
                    role="button"
                    onClick={() => handleShowTermsModal('/PrivacyStatement.pdf')}
                  >
                    Privacy Policy
                  </span>
                </>
              }
              onChange={(e) => setPrivacyPolicy(e.target.checked)}
            />
          </Form.Group>
          <Form.Group controlId="agreeToTC" className="mt-3">
            <Form.Check
              type="checkbox"
              label={
                <>
                  I agree to the{' '}
                  <span
                    className="text-primary text-decoration-underline"
                    role="button"
                    onClick={() => handleShowTermsModal('/TermsAndConditions.pdf')}
                  >
                    Terms and Conditions
                  </span>
                </>
              }
              onChange={(e) => setTermsCond(e.target.checked)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handlePasswordUpdate}
            disabled={!privacyPolicy || !termsCond || updatingPassword} // Disable button unless checkbox is checked
          >
            {updatingPassword ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
                Updating...
              </>
            ) : 'Update Password'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Terms and Privacy */}
      <Modal show={showTermsModal} onHide={handleCloseTermsModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>
            {modalTermsTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ width: '100%', height: '500px', border: 'none' }}
          ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HomePage;
