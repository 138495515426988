import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Modal, OverlayTrigger, Tooltip, Button, Pagination, Form, InputGroup, FormControl, Dropdown, DropdownButton, Col, Row } from 'react-bootstrap';
import VehicleList from './VehicleList';
import SalesOrderForm from './SalesOrderForm';
import { useFirestore } from '../contexts/FirestoreContext';
import { ChevronDown, ChevronUp, ArrowDownUp, InfoCircle, Trash, Wrench, PlusCircle, PlusCircleFill, Search, Funnel, FiletypeCsv, Coin, Buildings } from "react-bootstrap-icons";
import { saveAs } from 'file-saver';
import { useAuth } from '../contexts/AuthContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SalesOrderList({ isAdmin = false }) {
  const query = useQuery();
  const [salesOrders, setSalesOrders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [salesOrderToDelete, setSalesOrderToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [salesOrderToEdit, setSalesOrderToEdit] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [salesOrderToShow, setSalesOrderToShow] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(query.get('search') || '');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [localSalesOrders, setLocalSalesOrders] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // Initial sort direction is 'asc'

  const [selectedStatuses, setSelectedStatuses] = useState(['Active']); // Multi-select statuses
  const navigate = useNavigate();
  const { getData, deleteData, updateData, queryData, onSnapshotQueryData, onSnapshotData } = useFirestore();
  const cleanupRef = useRef(null);
  const [userCustomers, setUserCustomers] = useState([]); // Array of user customer numbers
  const { currentUser } = useAuth(); // Assuming useAuth provides currentUser
  const [viewAsUser, setViewAsUser] = useState(null); // Track the selected user to "view as"
  const [customerUsers, setCustomerUsers] = useState([]); // Track the list of users with the "Customer" role
  const [ adminProfile, setAdminProfile] = useState(null);
  const [selectedSalesOffice, setSelectedSalesOffice] = useState('All');

  useEffect(() => {
    const fetchData = () => {
      const salesOrderUnsubscribe = isAdmin
        ? onSnapshotData('salesOrders', (salesOrderData) => {
          salesOrderData.sort(compareSalesOrderNumbers);
          setSalesOrders(salesOrderData);
        })
        : onSnapshotQueryData('salesOrders', 'strCustNbr', 'in', userCustomers, (salesOrderData) => {
          salesOrderData.sort(compareSalesOrderNumbers);
          setSalesOrders(salesOrderData);
        });

      const customerUnsubscribe = onSnapshotData('customers', (customerData) => {
        setCustomers(customerData);
      });

      // Clean up the listeners when the component unmounts
      return () => {
        salesOrderUnsubscribe();
        customerUnsubscribe();
      };
    };

    if (isAdmin || userCustomers.length > 0) {
      fetchData();
    }
  }, [isAdmin, userCustomers, onSnapshotData, onSnapshotQueryData]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const users = await getData('users');
      if (!isAdmin && currentUser) {
        const userProfile = users.find(user => user.id === currentUser.uid);
        if (userProfile && Array.isArray(userProfile.customers)) {
          const updatedCustomers = [...userProfile.customers, '1002']; // Add 'NewCustomer' to the array
          setUserCustomers(updatedCustomers); // Set the updated customers array
        }
      }
      if (isAdmin && currentUser) {
        const adminProfile = users.find(user => user.id === currentUser.uid);
        setAdminProfile(adminProfile);
      }
    };

    fetchUserProfile();
  }, [isAdmin, currentUser, getData]);

  useEffect(() => {
    filterSalesOrders(searchQuery);
  }, [salesOrders, searchQuery, selectedCustomers, selectedStatuses, selectedSalesOffice]);

  useEffect(() => {
    const fetchCustomerUsers = async () => {
      if (isAdmin) {
        const users = await getData('users'); // Assuming `getData('users')` fetches all users
        const customers = users.filter(user => user.role === 'Customer');
        setCustomerUsers(customers);
      }
    };

    fetchCustomerUsers();
  }, [isAdmin, getData]);


  const getCustomerName = (customerNumber) => {
    const customer = customers.find(cust => cust.strCustNbr === customerNumber);
    return customer ? customer.strCustName : 'Unknown';
  };

  const filterSalesOrders = (query) => {
    const lowercasedQuery = query.toLowerCase();
    const filteredOrders = salesOrders.filter(order => {
      const customerName = getCustomerName(order.strCustNbr).toLowerCase();
      const matchesQuery = (
        order.strSalesOrdNbr?.toLowerCase().includes(lowercasedQuery) ||
        order.strSalesOffice?.toLowerCase().includes(lowercasedQuery) ||
        order.strPONbr?.toLowerCase().includes(lowercasedQuery) ||
        order.strCustNbr?.toLowerCase().includes(lowercasedQuery) ||
        customerName.includes(lowercasedQuery) ||
        order.strSalesOrdStatus?.toLowerCase().includes(lowercasedQuery)
      );

      const isCustomerIncluded = isAdmin
        ? (viewAsUser ? (viewAsUser.customers || []).includes(order.strCustNbr) : true)
        : userCustomers.includes(order.strCustNbr);

      // Filter by sales office
      const officeMatches = selectedSalesOffice === 'All' ||
        selectedSalesOffice === order.strSalesOffice;

      return matchesQuery && isCustomerIncluded &&
        (selectedCustomers.length === 0 || selectedCustomers.includes(order.strCustNbr)) &&
        (selectedStatuses.length === 0 || selectedStatuses.includes(order.strSalesOrdStatus)) &&
        officeMatches;
    });

    // Ensure "Stock" order is included for all users
    const stockOrder = salesOrders.find(order => order.strSalesOrdNbr === "Stock");
    if (stockOrder && !filteredOrders.includes(stockOrder)) {
      filteredOrders.unshift(stockOrder); // Add "Stock" order at the beginning
    }

    setLocalSalesOrders(filteredOrders);
    setTotalRows(filteredOrders.length);
    setCurrentPage(1); // Reset to the first page when filtering changes
  };

  const exportFordOrdersToCSV = (fordOrders, salesOrderNbr) => {
    if (!fordOrders || fordOrders.length === 0) {
      alert('No Ford Orders to export.');
      return;
    }

    // CSV format
    const csvContent = `Ford Order Number\n${fordOrders.join('\n')}`;
    const fileName = `(${salesOrderNbr})FordOrdersNumbers.csv`

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, fileName);
  };

  const handleCustomerSelect = (customerNumber) => {
    setSelectedCustomers(prevSelected => {
      if (prevSelected.includes(customerNumber)) {
        return prevSelected.filter(custNbr => custNbr !== customerNumber);
      } else {
        return [...prevSelected, customerNumber];
      }
    });
  };

  const handleStatusSelect = (status) => {
    setSelectedStatuses(prevSelected => {
      if (prevSelected.includes(status)) {
        return prevSelected.filter(stat => stat !== status);
      } else {
        return [...prevSelected, status];
      }
    });
  };

  // Helper function to extract the YYMMDD part and compare
  const compareSalesOrderNumbers = (a, b) => {
    const dateA = a.strSalesOrdNbr.split('-')[0]; // Get the YYMMDD part
    const dateB = b.strSalesOrdNbr.split('-')[0];

    return dateB.localeCompare(dateA); // Compare in descending order
  };

  const handleSalesOfficeSelect = (office) => {
    setSelectedSalesOffice(office);
  };

  const handleViewAsUserChange = (selectedUserId) => {
    const selectedUser = customerUsers.find(user => user.id === selectedUserId);
    if (selectedUser) {
      setViewAsUser(selectedUser); // Set the selected user to "view as"
      setUserCustomers(selectedUser.customers || []); // Filter by the selected user's customers
    } else {
      setViewAsUser(null); // Reset the view if "themselves" is selected
      setUserCustomers([]); // Show all orders if no specific user is selected
    }
  };



  const handleOrderRowClick = (order) => {
    if (selectedOrder && selectedOrder.strSalesOrdNbr === order.strSalesOrdNbr) {
      setSelectedOrder(null); // Deselect if the same order is clicked again
    } else {
      setSelectedOrder(order);
    }
  };

  const handleCustomerClick = (customerName) => {
    navigate(`/admin?section=customers&search=${customerName}`);
  }

  const handleSalesOrderAdded = async () => {
    const salesOrderData = await getData('salesOrders');
    setSalesOrders(salesOrderData);
    setShowAddModal(false); // Close the modal after adding the sales order
  };

  const handleSalesOrderUpdated = async (updatedSalesOrder) => {
    if (!updatedSalesOrder || !updatedSalesOrder.id) {
      console.error('Updated sales order or sales order ID is missing');
      return;
    }
    await updateData('salesOrders', updatedSalesOrder.id, updatedSalesOrder);
    const updatedSalesOrders = salesOrders.map(order => (order.id === updatedSalesOrder.id ? updatedSalesOrder : order));
    setSalesOrders(updatedSalesOrders);
  };

  const handleSalesOrderDeleted = async (salesOrder) => {
    await deleteData('salesOrders', salesOrder.id);

    // Fetch vehicles associated with the sales order using queryData
    const vehiclesSnapshot = await queryData('vehicles', 'strSalesOrdNbr', '==', salesOrder.strSalesOrdNbr);

    // Assuming queryData returns an array of document snapshots
    const vehiclesToUpdate = vehiclesSnapshot.map(doc => ({ id: doc.id, ...doc }));

    for (const vehicle of vehiclesToUpdate) {
      await updateData('vehicles', vehicle.id, { ...vehicle, strSalesOrdNbr: '' });
    }

    const updatedSalesOrders = salesOrders.filter(order => order.id !== salesOrder.id);
    setSalesOrders(updatedSalesOrders);
    setShowDeleteModal(false);
  };



  const handleCloseModal = () => {
    if (cleanupRef.current) {
      cleanupRef.current(); // Call the cleanup function if it exists
    }
    setShowAddModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortClick = (column) => {
    let direction = 'asc';

    if (sortColumn === column && sortDirection === 'asc') {
      direction = 'desc';
    } else if (sortColumn === column && sortDirection === 'desc') {
      direction = 'asc';
    }

    setSortColumn(column);
    setSortDirection(direction);

    let sortedOrders;
    if (column === 'strSalesOrdNbr') {
      sortedOrders = [...localSalesOrders].sort((a, b) => {
        // Split strSalesOrdNbr into date and sequence parts
        const [dateA, seqA] = a.strSalesOrdNbr.split('-').map((part, index) => index === 1 ? parseInt(part, 10) : part);
        const [dateB, seqB] = b.strSalesOrdNbr.split('-').map((part, index) => index === 1 ? parseInt(part, 10) : part);

        if (dateA !== dateB) {
          return direction === 'asc' ? dateA.localeCompare(dateB) : dateB.localeCompare(dateA);
        }
        return direction === 'asc' ? seqA - seqB : seqB - seqA;
      });
    } else if (column === 'customerName') {
      sortedOrders = [...localSalesOrders].sort((a, b) => {
        const nameA = getCustomerName(a.strCustNbr)?.toLowerCase();
        const nameB = getCustomerName(b.strCustNbr)?.toLowerCase();

        return direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      });
    } else {
      sortedOrders = [...localSalesOrders].sort((a, b) => {
        const fieldA = a[column]?.toLowerCase() || '';
        const fieldB = b[column]?.toLowerCase() || '';

        return direction === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      });
    }

    setLocalSalesOrders(sortedOrders);
  };


  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = localSalesOrders.slice(indexOfFirstRow, indexOfLastRow);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(localSalesOrders.length / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };


  return (
    <>
      {isAdmin && adminProfile &&(
        <div className="d-flex mb-3">
          <InputGroup className="me-2" style={{ flex: 1 }}>
            <InputGroup.Text id="view-as-addon">View As</InputGroup.Text>
            <Form.Select
              aria-label="View As"
              onChange={(e) => handleViewAsUserChange(e.target.value)}
            >
              <option value="">{adminProfile.name} ({adminProfile.email}) (Current User)</option>
              {customerUsers.map(user => (
                <option key={user.id} value={user.id}>
                  {user.name} ({user.email})
                </option>
              ))}
            </Form.Select>
          </InputGroup>
          <InputGroup style={{ flex: 1 }}>
            <InputGroup.Text id="search-addon"><Search /></InputGroup.Text>
            <FormControl
              placeholder="Search Sales Orders"
              aria-label="Search Sales Orders"
              aria-describedby="search-addon"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputGroup>
        </div>
      )}
      <Table hover>
        <thead>
          <tr>
            <th>
              {isAdmin && !viewAsUser &&
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Add Sales Order</Tooltip>}
              >
                <Button
                  onClick={() => setShowAddModal(true)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="icon-hover me-3"
                  variant="link"
                >
                  {isHovered ? <PlusCircleFill size={20} /> : <PlusCircle size={20} />}
                </Button>
              </OverlayTrigger>
              }
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Sales Order #</span>
                <Button variant='white' onClick={() => handleSortClick('strSalesOrdNbr')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Sales Office</span>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Filter by Sales Office</Tooltip>}
                >
                  <DropdownButton
                    as={InputGroup.Append}
                    variant="white"
                    title={<Funnel />}
                    id="input-group-dropdown-sales-office"
                  >
                    <Dropdown.Item
                      onClick={() => handleSalesOfficeSelect('All')}
                      active={selectedSalesOffice === 'All'}
                    >
                      All
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleSalesOfficeSelect('East')}
                      active={selectedSalesOffice === 'East'}
                    >
                      East
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleSalesOfficeSelect('West')}
                      active={selectedSalesOffice === 'West'}
                    >
                      West
                    </Dropdown.Item>
                  </DropdownButton>
                </OverlayTrigger>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>PO Number</span>
                <Button variant='white' onClick={() => handleSortClick('strPONbr')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Customer #</span>
                <Button variant='white' onClick={() => handleSortClick('strCustNbr')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Customer Name</span>
                <div className="d-flex align-items-center">
                  <Button variant='white' onClick={() => handleSortClick('customerName')} className="me-1">
                    <ArrowDownUp />
                  </Button>
                  {isAdmin && !viewAsUser && (
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Filter by Customer</Tooltip>}
                    >
                      <DropdownButton
                        as={InputGroup.Append}
                        variant="white"
                        title={<Funnel />}
                        id="input-group-dropdown-2"
                      >
                        {customers.map(customer => (
                          <Dropdown.Item
                            key={customer.strCustNbr}
                            active={selectedCustomers.includes(customer.strCustNbr)}
                            onClick={() => handleCustomerSelect(customer.strCustNbr)}
                          >
                            {customer.strCustName}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Status</span>
                <ArrowDownUp />
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Filter by Status</Tooltip>}
                >
                  <DropdownButton
                    as={InputGroup.Append}
                    variant="white"
                    title={<Funnel />}
                    id="input-group-dropdown-3"
                  >
                    {['Active', 'Void', 'Complete'].map(status => (
                      <Dropdown.Item
                        key={status}
                        active={selectedStatuses.includes(status)}
                        onClick={() => handleStatusSelect(status)}
                      >
                        {status}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </OverlayTrigger>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map(order => (
            <React.Fragment key={order.strSalesOrdNbr}>
              <tr>
                <td onClick={() => handleOrderRowClick(order)}>
                  {selectedOrder && selectedOrder.strSalesOrdNbr === order.strSalesOrdNbr ? <ChevronUp /> : <ChevronDown />}
                  {"   "}
                  {order.bDOASReq && (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">DOAS Required</Tooltip>}>
                      <span style={{ marginLeft: '10px' }}><Buildings /></span>
                    </OverlayTrigger>
                  )}
                </td>
                <td>{order.strSalesOrdNbr}</td>
                <td>{order.strSalesOffice}</td>
                <td style={{ backgroundColor: ((!isAdmin || viewAsUser) && (!order.strPONbr || order.strPONbr === '' || order.strPONbr === 'undefined')) ? 'red' : 'transparent' }}>
                  {order.strPONbr || 'N/A'}
                </td>
                {isAdmin ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Navigate to Customer</Tooltip>}
                  >
                    <td onClick={() => handleCustomerClick(getCustomerName(order.strCustNbr))}>
                      {order.strCustNbr}
                    </td>
                  </OverlayTrigger>
                ) : (
                  <td>{order.strCustNbr}</td>
                )}
                {isAdmin ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Navigate to Customer</Tooltip>}
                  >
                    <td onClick={() => handleCustomerClick(getCustomerName(order.strCustNbr))}>
                      {getCustomerName(order.strCustNbr)}
                    </td>
                  </OverlayTrigger>
                ) : (
                  <td>{getCustomerName(order.strCustNbr)}</td>
                )}
                <td>{order.strSalesOrdStatus}</td>
                <td>
                  {isAdmin && !viewAsUser &&
                  <>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Detail</Tooltip>}>
                      <Button
                        onClick={() => { setSalesOrderToShow(order); setShowDetailModal(true) }}
                        variant="white"
                        style={{ padding: '2px 4px' }}
                      >
                        <InfoCircle color="blue" />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}>
                      <Button
                        onClick={() => { setSalesOrderToEdit(order); setShowEditModal(true); }}
                        variant="white"
                        style={{ padding: '2px 4px' }}
                      >
                        <Wrench color="black" />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
                      <Button
                        onClick={() => { setSalesOrderToDelete(order); setShowDeleteModal(true); }}
                        variant="white"
                        style={{ padding: '2px 4px' }}
                      >
                        <Trash color="red" />
                      </Button>
                    </OverlayTrigger>
                  </>
                  }
                </td>
              </tr>
              {selectedOrder && selectedOrder.strSalesOrdNbr === order.strSalesOrdNbr && (
                <tr>
                  <td colSpan="7">
                    <VehicleList
                      selectedOrder={selectedOrder}
                      addButtonActive={isAdmin && !viewAsUser}
                      rowPages={999999}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center my-3 mb-5" style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this sales order?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => handleSalesOrderDeleted(salesOrderToDelete)}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Sales Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalesOrderForm
            salesOrder={salesOrderToEdit}
            onSalesOrderUpdated={(updatedSalesOrder) => {
              handleSalesOrderUpdated(updatedSalesOrder);
              setShowEditModal(false);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Sales Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {salesOrderToShow && (
            <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              <Row>
                <Col><strong>Sales Order Number:</strong></Col>
                <Col>{salesOrderToShow.strSalesOrdNbr}</Col>
              </Row>
              <Row>
                <Col><strong>Requested DANs:</strong></Col>
                <Col>{salesOrderToShow.arrDans && salesOrderToShow.arrDans.length > 0 ? salesOrderToShow.arrDans.join(', ') : 'No DANs requested'}</Col>
              </Row>
              <Row>
                <Col>
                  <strong>Requested Ford Orders:</strong>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Export to CSV</Tooltip>}
                  >
                    <Button
                      variant="link"
                      onClick={() => exportFordOrdersToCSV(salesOrderToShow.arrFordOrders, salesOrderToShow.strSalesOrdNbr)}
                      style={{ marginLeft: '10px' }}
                    >
                      <FiletypeCsv size={20} color="green" />
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col>
                  {salesOrderToShow.arrFordOrders && salesOrderToShow.arrFordOrders.length > 0 ? (
                    <>
                      {salesOrderToShow.arrFordOrders.join(', ')}
                    </>
                  ) : (
                    'No Ford Orders requested'
                  )}
                </Col>
              </Row>
              <Row>
                <Col><strong>Customer Number:</strong></Col>
                <Col>{salesOrderToShow.strCustNbr}</Col>
              </Row>
              <Row>
                <Col><strong>Customer Name:</strong></Col>
                <Col>{getCustomerName(salesOrderToShow.strCustNbr)}</Col>
              </Row>
              <Row>
                <Col><strong>Status:</strong></Col>
                <Col>{salesOrderToShow.strSalesOrdStatus}</Col>
              </Row>
              <Row>
                <Col><strong>Number of Keys Required:</strong></Col>
                <Col>{salesOrderToShow.nKeysReq}</Col>
              </Row>
              <Row>
                <Col><strong>Sales Office:</strong></Col>
                <Col>{salesOrderToShow.strSalesOffice}</Col>
              </Row>
              <Row>
                <Col><strong>PO Number:</strong></Col>
                <Col>{salesOrderToShow.strPONbr}</Col>
              </Row>
              <Row>
                <Col><strong>PO Verified Date:</strong></Col>
                <Col>{salesOrderToShow.dtPOVerifiedDate ? salesOrderToShow.dtPOVerifiedDate.toDate().toISOString().split('T')[0] : 'N/A'}</Col>
              </Row>
              <Row>
                <Col><strong>Sales ID:</strong></Col>
                <Col>{salesOrderToShow.strSalesId}</Col>
              </Row>
              <Row>
                <Col><strong>Sales Order Memo:</strong></Col>
                <Col>{salesOrderToShow.lstrSalesOrdMemo}</Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showAddModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Add Sales Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SalesOrderForm
            onSalesOrderAdded={handleSalesOrderAdded}
            onModalClose={handleCloseModal} // Pass the close handler to the form
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SalesOrderList;
