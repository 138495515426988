// src/components/Footer.js
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const handleShowModal = (url) => {
    if (url === '/PrivacyStatement.pdf') {
      setModalTitle('Privacy Statement')
    } else {
      setModalTitle('Terms and Conditions')
    }
    setPdfUrl(url);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPdfUrl('');
    setModalTitle('');
  };
  return (
    <>
      <footer className="bg-light text-center text-lg-start fixed-bottom">
        <div className="text-center p-1">
          © 2025 Hardy Fleet Portal |{' '}
          <span
            className="text-primary text-decoration-underline"
            role="button"
            onClick={() => handleShowModal('/PrivacyStatement.pdf')}
          >
            Privacy Policy
          </span>{' '}
          |{' '}
          <span
            className="text-primary text-decoration-underline"
            role="button"
            onClick={() => handleShowModal('/TermsAndConditions.pdf')}
          >
            Terms and Conditions
          </span>
        </div>
      </footer>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '18px', textAlign: 'center', width: '100%' }}>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ width: '100%', height: '500px', border: 'none' }}
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Footer;

